* {
  margin: 0px;
  padding: 0px;
}

body {
  background-color: white;
}

.field {
  --CELL_SIZE: 50px;
  --GRID_SIZE: 8;
  display: grid;
  gap: 0;
  height: 100vh;
  width: 100vw;
  grid-template-rows: repeat(var(--GRID_SIZE), var(--CELL_SIZE));
  grid-template-columns: repeat(var(--GRID_SIZE), var(--CELL_SIZE));

  /* ! */
  justify-content: center;
  align-content: center;
}

.cell {
  /* border: black 1px solid; */
  text-align: center;
}

.No {
  background-color: white;
}
.First {
  background-color: red;
}
.Second {
  background-color: aqua;
}
